<template>
    <div class="body">
        <div class="background">
            <div class="header-content">
                <p class="price">BEAUTIFY YOUR HOMES WITH LIGHT BEAULT PLACE</p>
                
                <hr />
                
                <h1 class="title">ORDER NOW!</h1>

                <p class="description">
                    WE HELP BEAUTIFY YOUR HOMES. BRIGHTEN DARK SIDE AND GIVE BEAULTIFUL VIEWS TO  YOUR,COMPANIES AND MORE. 
                    We Have Sleek Designs That Provides Both Style, Condusive And Pleasantry Enviroment.
                </p>
            </div>
        </div>
        <HeaderPage id="nav" />

    </div>
</template>

<script>
    import HeaderPage from './HeaderPage.vue'
    export default {
        name: 'MainPage',
        components: {
            HeaderPage
        }
    }
</script>

<style scoped>
    .body{
        height: 100vh; 
        position: relative;
        color: white;
    }
    .background {
        background-image: url('@/assets/team1.jpg');
        background-size: cover;
        background-position: center;
        background-color: inherit;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }
    .nav{
        position: absolute; 
        top: 2rem;
        left: 0;
        right: 0;
        padding: 1rem 2rem;
    }
    .price {
        font-size: 1.5em;
        margin: 20px 0;
    }
    hr{
        color: white;
        width: 80px;
        margin-left: 0px;
    }
    .header-content {
        max-width: 600px;
        margin-left: 100px;
        text-align: left;
        padding-top: 200px;
        
    }

    .header-content h1 {
        margin-bottom: 40px;
    }

    .title {
        font-size: 3em;
        margin: 0;
    }

    .description {
        font-size: 1.2em;
        margin: 10px 0;
    }
    @media (max-width: 768px) {
    .body {
        display: none;
    }
    }
</style>